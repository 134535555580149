import React from 'react';
import { Input } from 'reactstrap';
import { BED_TYPES } from '../../common/constant';

export function Filter({ column }) {
  return (
    <>
      {column.canFilter && (
        <div style={{ marginTop: 5 }}>
          {column.canFilter && column.render('Filter')}
        </div>
      )}
    </>
  );
}

export function DefaultColumnFilter({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) {
  return (
    <Input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`search (${length}) ...`}
    />
  );
}

export function SelectColumnFilter({
  column: {
    filterValue, setFilter, preFilteredRows, id,
  },
}) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <select
      id="custom-select"
      className="form-select"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export const fetchBedType = (bedCount) => {
    switch (bedCount) {
      case 1:
        return BED_TYPES.SINGLE;
      case 2:
        return BED_TYPES.DOUBLE;
      case 3:
        return BED_TYPES.TRIPLE;
      default:
        return `${bedCount}th Sharing`
    }
  };
