import React, { useEffect, useState } from "react";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
} from "@react-pdf/renderer";
import logo from '../../assets/images/logo1.png';
import phoneIcon from '../../assets/images/pdfPhone.png';
import globeIcon from '../../assets/images/pdfEmail.png';
import pdfAddress from '../../assets/images/pdfAddress.png'
import pdfFooterBack from '../../assets/images/pdfFooterBack.png'
import AddressComponent from "../Common/Address";
import moment from "moment";
import { DEFAULT_DATETIME_FORMAT } from "../../common/constant";
import { getAllGlobalSettings } from "../../api/globalSettings";

const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        position: 'relative',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
        marginTop: 20,
    },
    logo: {
        width: 150,
    },
    content: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
        paddingLeft: 30,
        paddingRight: 30,
    },
    pdfTable: {
        paddingLeft: 30,
        paddingRight: 30,
    },
    contentNote: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    column: {
        width: '35%',
    },
    title: {
        fontSize: 14,
        marginBottom: 5,
        color: '#7B7B7B'
    },
    titleNote: {
        fontSize: 16,
        marginBottom: 5,
        color: '#231F20',
        fontWeight: 'medium'
    },
    tableTitle: {
        fontSize: 16,
        marginBottom: 5,
    },
    dataValueTable: {
        fontSize: 14,
        marginBottom: 10,
    },
    dataValueTableTotal: {
        fontSize: 16,
        marginBottom: 10,
    },
    dataValue: {
        fontSize: 12,
        marginBottom: 10,
        color: '#414042',
        fontWeight: 'medium'
    },
    table: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    tableLast: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderTopWidth: 1,
        borderBottomWidth: 1,
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        padding: '4px 8px',
        backgroundColor: '#E8E8E8',
        fontSize: 15
    },
    tableCell: {
        fontSize: 10,
        padding: 5,
        flex: 1,
        borderRightColor: '#A7A9AC',
        borderRightWidth: 1,
    },
    tableCellTotal: {
        marginTop: 10
    },
    tableCellLast: {
        fontSize: 10,
        padding: 5,
        flex: 1,
        textAlign: 'center',
    },
    firstCell: {
        paddingTop: 15,
    },
    tableCellHeader: {
        fontSize: 10,
        padding: 5,
        flex: 1,
        textAlign: 'center',
    },
    footer: {
        position: 'relative',
        width: '100%',
        fontSize: 12,
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 'auto',
        alignItems: 'center',
        height: 150
    },
    footerBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%', // Ensure full coverage
        zIndex: -1,
    },
    footerLeft: {
        width: '50%',
        flexDirection: 'column',
        padding: 10,
    },
    footerRight: {
        width: '40%',
        flexDirection: 'column',
        padding: 10,
    },
    footerText: {
        color: '#FFFFFF',
        marginLeft: 5,
        fontSize: 16,
    },
    footerIcon: {
        width: 22,
        height: 22,
        marginRight: 5,
        color: '#FFFFFF',
    },
    footerRow: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
    },
});

const RentSlipPdf = (props) => {
    const { item } = props;
    const [platformFee, setPlatformFee] = useState(null);
    const fetchGlobalSettings = async () => {
        try {
            const response = (await getAllGlobalSettings({ search: 'platform_fee' })).data.data
            setPlatformFee(parseInt(response.data[0].payload?.value));
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchGlobalSettings();
    }, [])
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.headerContainer}>
                    <Image src={logo} style={styles.logo} />
                </View>
                <View style={styles.content}>
                    {/* Left Column */}
                    <View style={styles.column}>
                        <Text style={styles.title}>Property Name:</Text>
                        <Text style={styles.dataValue}>
                            {item?.property_name}
                        </Text>

                        <Text style={styles.title}>Address:</Text>
                        <Text style={styles.dataValue}>
                            <AddressComponent
                                address={item?.property_address}
                                getOnlyString
                            />
                        </Text>

                        <Text style={styles.title}>Rent of Which Month & Year:</Text>
                        <Text style={styles.dataValue}>
                            {moment(item.start_date).format('MMMM, YYYY')}
                        </Text>
                    </View>

                    {/* Right Column */}
                    <View style={styles.column}>
                        <Text style={styles.title}>Tenant Name:</Text>
                        <Text style={styles.dataValue}>
                            {item?.name}
                        </Text>

                        <Text style={styles.title}>Payment Date & Time:</Text>
                        <Text style={styles.dataValue}>
                            {moment(item?.payment_history?.payment_date).format(DEFAULT_DATETIME_FORMAT)}
                        </Text>

                        <Text style={styles.title}>Transaction ID:</Text>
                        <Text style={styles.dataValue}>
                            {item?.payment_history?.transaction_id}
                        </Text>

                        <Text style={styles.title}>Payment Mode:</Text>
                        <Text style={styles.dataValue}>
                            {item?.payment_history?.payment_mode}
                        </Text>
                    </View>
                </View>
                <View style={styles.pdfTable}>
                    <View style={styles.tableHeader}>
                        <View style={styles.tableCellHeader}>
                            <Text style={styles.tableTitle}>PARTICULARS</Text>
                        </View>
                        <View style={styles.tableCellHeader}>
                            <Text style={styles.tableTitle}>AMOUNT</Text>
                        </View>
                    </View>

                    <View style={styles.table}>
                        <View style={[styles.tableCell, styles.firstCell]}>
                            <Text style={styles.dataValueTable}>Rent Amount</Text>
                        </View>
                        <View style={[styles.tableCellLast, styles.firstCell]}>
                            <Text style={styles.dataValueTable}>{item?.rent}</Text>
                        </View>
                    </View>

                    <View style={styles.table}>
                        <View style={styles.tableCell}>
                            <Text style={styles.dataValueTable}>Electricity Amount</Text>
                        </View>
                        <View style={styles.tableCellLast}>
                            <Text style={styles.dataValueTable}>{item?.electricityAmount}</Text>
                        </View>
                    </View>

                    <View style={styles.table}>
                        <View style={styles.tableCell}>
                            <Text style={styles.dataValueTable}>Other Charges</Text>
                        </View>
                        <View style={styles.tableCellLast}>
                            <Text style={styles.dataValueTable}>{item?.otherAmount}</Text>
                        </View>
                    </View>

                    <View style={styles.table}>
                        <View style={styles.tableCell}>
                            <Text style={styles.dataValueTable}>Platform Fee</Text>
                        </View>
                        <View style={styles.tableCellLast}>
                            <Text style={styles.dataValueTable}>{platformFee}</Text>
                        </View>
                    </View>

                    <View style={[styles.table, styles.tableLast]}>
                        <View style={[styles.tableCell]}>
                            <Text style={[styles.dataValueTableTotal, styles.tableCellTotal]}>Total</Text>
                        </View>
                        <View style={styles.tableCellLast}>
                            <Text style={[styles.dataValueTableTotal, styles.tableCellTotal]}>{item?.payment_history?.total_amount || 0}</Text>
                        </View>
                    </View>

                    {/* <View style={styles.contentNote}>
                        <View style={styles.column}>
                            <Text style={styles.titleNote}>Note</Text>
                        </View>
                    </View> */}
                </View>

                {/* Footer */}
                <View style={styles.footer}>
                    <Image src={pdfFooterBack} style={styles.footerBackground} />
                    <View style={styles.footerLeft}>
                        <View style={styles.footerRow}>
                            <Image src={pdfAddress} style={styles.footerIcon} />
                            <Text style={styles.footerText}>
                                Debkripa, Plot 09 street 574 AA 2B, near Akankha More, Kolkata,
                            </Text>
                        </View>
                    </View>
                    <View style={styles.footerRight}>
                        <View style={styles.footerRow}>
                            <Image src={phoneIcon} style={styles.footerIcon} />
                            <Text style={styles.footerText}>6289710480</Text>
                        </View>
                        <View style={styles.footerRow}>
                            <Image src={globeIcon} style={styles.footerIcon} />
                            <Text style={styles.footerText}>admin@dstayz.com</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default RentSlipPdf;
