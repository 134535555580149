import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button } from 'reactstrap';
import MaskGroup from '../../assets/images/Mask group.png';
import { Skeleton } from "@mui/material";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import dayjs from 'dayjs';
import { PAYMENT_MODE } from '../../common/constant';
import GenerateCancelRequest from '../../components/Dashboard/generateCancelRequest';
import { fetchCancelBooking } from '../../api/cancel_request';
import { fetchBedType } from '../../components/Common/filters';

export function CancelBooking() {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [cancelBookingData, setCancelBookingData] = useState();
    const [bedDetails, setBedDetails] = useState();
    const [isCancelBookingButtonEnable, setIsCancelBookingButtonEnable] = useState(false)
    document.title = 'Cancel Booking | DStayZ';

    useEffect(() => {
        fetchTenantDetails()
    }, [])

    const fetchTenantDetails = async () => {
        try {
            setIsLoading(true)
            const response = (await fetchCancelBooking()).data.data
            if (response?.tenant) {
                setIsCancelBookingButtonEnable(dayjs().isBefore(dayjs(response?.tenant?.joining_date)))
                setBedDetails({ tenant_id: response.tenant.id, bed_id: response.tenant?.user?.bed?.id })
            } else {
                setIsCancelBookingButtonEnable(dayjs().isBefore(dayjs(response?.cancel_booking?.data[0]?.tenant?.joining_date)))
                setCancelBookingData(response.cancel_booking.data)
                setBedDetails({ tenant_id: response.cancel_booking.data[0].tenant_id, bed_id: response.cancel_booking.data[0]?.tenant?.user?.bed?.id })
            }
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="page-content">
            <Container fluid>
                <div className='complain_ticket'>
                    <div className='inner_page_header'>
                        Cancel Booking
                    </div>
                    <div className='overall_tickets list-inline'>
                        {cancelBookingData ? (
                            cancelBookingData?.map((item, index) => (
                                <div className='mb-4' key={index}>
                                    <div className='single_ticket'>
                                        <div className='single_ticket_header d-flex justify-content-between align-items-center'>
                                            <div className='ticket_id d-flex align-items-center'>
                                                <CalendarTodayOutlinedIcon />
                                                <div className='mx-2'>Cancel Date </div>
                                                <b>{dayjs(item?.cancelled_date).format('DD-MM-YYYY')}</b>
                                            </div>
                                            <div className="rs_header_right d-flex">
                                                <div
                                                    className={'ticket_id me-3'}
                                                    style={{
                                                        borderRight: "1px solid #ddd",
                                                        paddingRight: "12px",
                                                        paddingLeft: '12px'
                                                    }}
                                                >
                                                    Booking Date: <b> {dayjs(item.booking_date).format('DD-MM-YYYY')}</b>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='single_ticket_body'>
                                            <div className='leaving_reason'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div>
                                                        <div className='bankAccountDet'>
                                                            <h5 className='mb-3'>Cancel Booking Reason:</h5>
                                                        </div>
                                                        {/* <div className='responsive_word_break'>Cancel Booking Reason</div> */}
                                                        <div><b>{item.note?.description}</b></div>
                                                    </div>
                                                </div>
                                                <div className='col-12 mt-4'>
                                                    <div className='bankAccountDet'>
                                                        <h5 className='mb-3'>Property Details</h5>
                                                    </div>
                                                    <Row>
                                                        <div className='col-sm-4 d-flex align-items-start custom_border_bottom pb-2'>
                                                            <div className='tenantDetailTitle'>Property Name :</div>
                                                            <div className='tenantDetailDesc'>
                                                                {item?.property?.name ?? 'N/A'}
                                                            </div>
                                                        </div>

                                                        <div className='col-sm-4 d-flex align-items-start custom_border_bottom pb-2'>
                                                            <div className='tenantDetailTitle'>Floor No. :</div>
                                                            <div className='tenantDetailDesc'>
                                                                {item?.floor?.name === '0th' ? 'Ground' : item?.floor?.name ?? 'N/A'}
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-4 d-flex align-items-start custom_border_bottom pb-2'>
                                                            <div className='tenantDetailTitle'>Flat No. :</div>
                                                            <div className='tenantDetailDesc'>
                                                                {item?.flat?.name ?? 'N/A'}
                                                            </div>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        <div className='col-sm-4 d-flex align-items-start custom_border_bottom pb-2'>
                                                            <div className='tenantDetailTitle'>Room No. :</div>
                                                            <div className='tenantDetailDesc'>
                                                                {item?.room?.name ?? 'N/A'}
                                                            </div>
                                                        </div>

                                                        <div className='col-sm-4 d-flex align-items-start custom_border_bottom pb-2'>
                                                            <div className='tenantDetailTitle'>Bed No. :</div>
                                                            <div className='tenantDetailDesc'>
                                                                {item?.bed?.name ?? 'N/A'}
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-4 d-flex align-items-start custom_border_bottom pb-2'>
                                                            <div className='tenantDetailTitle'>Room Type :</div>
                                                            <div className='tenantDetailDesc'>
                                                                {item.room?.room_type?.name}{" "}
                                                                {fetchBedType(
                                                                    item.room?.bed_count
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>
                                                <div className='col-12 mt-4'>
                                                    <div className='bankAccountDet'>
                                                        <h5 className='mb-3'>Payment Details</h5>
                                                    </div>
                                                    <Row>
                                                        <div className={`${item.payment?.payment_mode === PAYMENT_MODE.UPI ? 'col-sm-4' : 'col-sm-6'} d-flex align-items-start custom_border_bottom pb-2`}>
                                                            <div className='tenantDetailTitle'>Refund Amount :</div>
                                                            <div className='tenantDetailDesc'>
                                                                {item?.payment?.total_amount ?? 'N/A'}
                                                            </div>
                                                        </div>

                                                        <div className={`${item.payment?.payment_mode === PAYMENT_MODE.UPI ? 'col-sm-4' : 'col-sm-6'} d-flex align-items-start custom_border_bottom pb-2`}>
                                                            <div className='tenantDetailTitle'>Refund Status :</div>
                                                            <div className='tenantDetailDesc'>
                                                                {item?.payment?.status ?? 'N/A'}
                                                            </div>
                                                        </div>

                                                        {item.payment?.payment_mode === PAYMENT_MODE.UPI && (
                                                            <div className='col-sm-4 d-flex align-items-start custom_border_bottom pb-2'>
                                                                <div className='tenantDetailTitle'>UPI ID :</div>
                                                                <div className='tenantDetailDesc'>
                                                                    {item?.payment?.upi_id ?? 'N/A'}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Row>
                                                    <Row>
                                                        <div className={`${item.payment?.payment_mode === PAYMENT_MODE.UPI ? 'col-sm-4' : 'col-sm-6'} d-flex align-items-start custom_border_bottom pb-2`}>
                                                            <div className='tenantDetailTitle'>Transaction Id :</div>
                                                            <div className='tenantDetailDesc'>
                                                                {item?.payment?.transaction_id ?? 'N/A'}
                                                            </div>
                                                        </div>
                                                        <div className={`${item.payment?.payment_mode === PAYMENT_MODE.UPI ? 'col-sm-4' : 'col-sm-6'} d-flex align-items-start custom_border_bottom pb-2`}>
                                                            <div className='tenantDetailTitle'>Refund Mode :</div>
                                                            <div className='tenantDetailDesc'>
                                                                {item?.payment?.payment_mode?.toUpperCase() ?? 'N/A'}
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>
                                                {item.payment.payment_mode === PAYMENT_MODE.BANK && item?.tenant?.bank_detail && (
                                                    <div className='col-12 mt-4'>
                                                        <div className='bankAccountDet'>
                                                            <h5 className='mb-3'>Bank Account Details</h5>
                                                        </div>
                                                        <Row>
                                                            <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                                                                <div className='tenantDetailTitle'>Bank Name :</div>
                                                                <div className='tenantDetailDesc'>
                                                                    {item?.tenant?.bank_detail?.bank_name ?? 'N/A'}
                                                                </div>
                                                            </div>

                                                            <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                                                                <div className='tenantDetailTitle'>IFSC :</div>
                                                                <div className='tenantDetailDesc'>
                                                                    {item?.tenant?.bank_detail?.ifsc ?? 'N/A'}
                                                                </div>
                                                            </div>
                                                        </Row>
                                                        <Row>
                                                            <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                                                                <div className='tenantDetailTitle'>Account Name :</div>
                                                                <div className='tenantDetailDesc'>
                                                                    {item?.tenant?.bank_detail?.account_holder_name ?? 'N/A'}
                                                                </div>
                                                            </div>

                                                            <div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-2'>
                                                                <div className='tenantDetailTitle'>Account Number :</div>
                                                                <div className='tenantDetailDesc'>
                                                                    {item?.tenant?.bank_detail?.account_number ?? 'N/A'}
                                                                </div>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) :
                            (isLoading &&
                                <Col md={12}>
                                    <Row>
                                        <Col md={12}>
                                            <Skeleton variant="rounded" className="p-2 m-2 h-75" ></Skeleton>
                                        </Col>
                                        <Col md={12}>
                                            <Skeleton variant="rounded" className="p-2 m-2 h-75" ></Skeleton>
                                        </Col>
                                        <Col md={12}>
                                            <Skeleton variant="rounded" className="p-2 m-2 h-75" ></Skeleton>
                                        </Col>
                                        <Col md={12}>
                                            <Skeleton variant="rounded" className="p-2 m-2 h-75" ></Skeleton>
                                        </Col>
                                        <Col md={12}>
                                            <Skeleton variant="rounded" className="p-2 m-2 h-75" ></Skeleton>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        }
                    </div>
                    {(!cancelBookingData || (cancelBookingData?.length > 0 && bedDetails?.bed_id && isCancelBookingButtonEnable)) && !isLoading && (
                        <div className='pg_vacant_request'>
                            <img src={MaskGroup} alt={MaskGroup} />
                            <div className='pg_vacant_request_content mt-3 text-center'>
                                <h5>No Active Cancel Booking Found!</h5>
                                {isCancelBookingButtonEnable && (
                                    <><p>If you want to cancel this booking click the Cancel Booking button</p><Button className="yellow_gradient_btn pay_full_amt" onClick={handleClickOpen} disabled={!bedDetails?.bed_id} style={bedDetails?.bed_id ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}> Cancel Booking </Button></>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Container>
            {open && (<GenerateCancelRequest open={open} onClose={handleClose} bedDetails={bedDetails} refresh={fetchTenantDetails} />)}
        </div>
    );
}
