import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import React from 'react'
import { getPropertyRatingDetails, updatePropertyRatingValue } from '../../api/property';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Avatar, Button, IconButton, Rating, TextField, Tooltip, Typography } from '@mui/material';
import { getS3BaseUrl } from '../../helpers/string_helper';
import { useFormik } from 'formik';
import moment from 'moment';
import EditIcon from "@mui/icons-material/Edit";
import { KNOWN_ROLES } from '../../common/constant';


function ReviewRatingModal({ isOpen, setClose, property_id, property_name, tenant_name, tenant_profile_photo }) {
  const [newVal, setNewVal] = React.useState(0);
  const [isEditable, setisEditable] = React.useState(false);
  const [rating, setRating] = React.useState({
    my_rating: null,
    my_review: null,
  });
  const formik = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      rating: null,
      review: '',
    },
    validationSchema: Yup.object({
      rating: Yup.number().required('Please give a rating'),
      review: Yup.string(),
    }),
    onSubmit: async (payload) => {
      try {
        if (rating?.my_rating !== payload?.rating || rating?.my_review?.content !== payload?.review) {
          const ratingResponse = await updatePropertyRatingValue({ rating: payload?.rating, comment: payload?.review, comment_id: rating?.my_review?.id }, property_id);
          if (ratingResponse?.status === 200) {
            toast.success("Your review has been submitted successfully");
            setClose();
          } else {
            toast.error(ratingResponse.data.message);
          }
        }
      } catch (error) {
        toast.error(error.response.data.message)
        console.log(error)
      }
    }
  });
  const getRatingValue = async () => {
    try {
      const response = await getPropertyRatingDetails(property_id);
      const ratingObj = response?.data?.data;
      setRating((prev) => ({
        ...prev,
        my_rating: ratingObj?.myRating || 0,
        my_review: ratingObj?.myReview,
      }));
      formik.setFieldValue('rating', ratingObj?.myRating);
      formik.setFieldValue('review', ratingObj?.myReview?.content);

    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
  };

  React.useEffect(() => {
    getRatingValue();
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      centered
      className="tenantDetailsDrawer tenanatProfileDetailDrawer tenant_dashboard"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalHeader
        toggle={setClose}
        color='primary'
      >
        {property_name ?? 'Add Review'}
      </ModalHeader>
      <ModalBody>
        <div className="amenityImagesSlider d-flex flex-column justify-content-center align-items-center">
          <div
            className='my-2 d-flex justify-content-center align-self-start'
          >
            <Avatar
              sizes='large'
              src={`${getS3BaseUrl()}${tenant_profile_photo}`}
              alt={tenant_name}
            />
            <div
              className='d-flex flex-column ms-2'
            >
              <Typography>
                {tenant_name}
              </Typography>
              <Typography
                variant="caption"
              >
                Posting publicly across Dstayz platform
                &nbsp;
                <Tooltip
                  title="Reviews may appear publicly with your profile name, picture, or link to your profile."
                >
                  <InfoOutlinedIcon
                    fontSize="small"
                  />
                </Tooltip>
              </Typography>
            </div>
          </div>
          <Rating
            name="simple-controlled"
            value={formik?.values?.rating}
            onChangeActive={(event, newValue) => {
              setNewVal(newValue);
            }}
            onClick={(e) => {
              e.preventDefault();
              formik.setFieldValue('rating', newVal)
            }}
            sx={{
              fontSize: "2.5rem"
            }}
          />
          <IconButton
            className='align-self-end'
            onClick={() => setisEditable((prev) => !prev)}
          >
            <EditIcon />
          </IconButton>
          <TextField
            className='form-control mt-2'
            label="Share your experience here"
            name='review'
            value={formik?.values?.review ?? ''}
            onChange={formik?.handleChange}
            onBlur={formik.handleBlur}
            disabled={!isEditable}
            multiline
            minRows={3}
            maxRows={4}
          />
          <div
            className='w-100 my-2 d-flex justify-content-center'
          >
            <Button
              variant='outlined'
              // color='black'
              size='small'
              className='me-1'
              onClick={setClose}
            >
              CANCLE
            </Button>
            <Button
              onClick={formik.handleSubmit}
              className={`${formik.isValid ? 'yellow_gradient_btn' : 'btn'} me-2`}
              disabled={!formik.isValid}
            >
              POST
            </Button>
          </div>
          <div className='w-100 mt-3'>
            {rating?.my_review?.replies?.length > 0 && rating?.my_review?.replies.map((reply, index) =>
            (<div key={index} className='review_replies mt-1 d-flex justify-content-between align-items-center'>
              {/* Reply */}
              <div>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: 'text.primary', display: 'inline' }}
                >
                  Response from {reply?.commenter?.role?.role !== KNOWN_ROLES.OWNER ? 'Dstayz Admin' : `${reply?.commenter?.name} (${reply?.commenter?.role?.role})`}
                  {moment(reply.created_at).isBefore(reply.updated_at) ? ` ${moment(reply.updated_at).utc().fromNow()} (edited)` : ` ${moment(reply.created_at).utc().fromNow()}`}
                </Typography>
                <div
                  style={{ opacity: '0.8' }}
                >
                  {reply?.content}
                </div>
              </div>
            </div>)
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ReviewRatingModal